/**
   Component: timeline.less
*/
 
@timeline-badge-size:           50px;
@timeline-badge-size-sm:        30px;
@timeline-datetime-height:      20px;
@timeline-separator-width:      40px;

// Timeline container
// --------------------------------
.timeline,
.timeline-alt {
  list-style: none;
  padding: 0 0 10px;
  position: relative;
  margin: 0;
  
  // Timeline central line
  &:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 1px;
    background-color: @gray-light;
    left: @timeline-separator-width / 2;
  }

  // Timeline list itmes
  > li {
    .clearfix();
    margin-bottom: 20px;
    position: relative;
    margin-top: 30px;
    &:first-child {
      margin-top: 0;
    }

    &.timeline-separator {
      position: relative;
      &:before {
        content: attr(data-datetime);
        display: inline-block;
        min-width: @timeline-separator-width;
        text-align: center;
        padding: 0 18px;
        line-height: 28px;
        font-size: 11px;
        background-color: @gray-light;
        // color: @brand-inverse;
        border-radius: 30px;
        margin-left: @timeline-separator-width;
      }
      &:after {
        @wd: 10px;
        content: "";
        width: @wd;
        height: @wd;
        position: absolute;
        top: 50%;
        left: (@timeline-separator-width / 2) - (@wd/2);
        margin-top: -(@wd/2);
        background-color: @gray-light;
        border-radius: 50%;
      }
    }

    // Timeline panel
    > .timeline-panel {
        position: relative;
        //float: right;
        margin-left: @timeline-separator-width + 10;

        // Popover inside timeline panels
        .popover {
          position: relative;
          display: block;
          margin: 0;
          width: 100%;
          max-width: none;
          .arrow {
            display: none;
          }
          .popover-content {
            .popover-title {
              background-color: transparent;
              border: 0;
              padding-left: 0;
              padding-top: 0;
            }
          }
        }
    }

    // Timeline Date

     > .timeline-date {
      float: right;
      margin: -@timeline-datetime-height 0 0;
      display: block;
      height: @timeline-datetime-height;
      line-height: @timeline-datetime-height;
      font-size: 11px;
      color: @text-muted;

      > time {
        &:after {
          content: attr(datetime);
        }
      }
    }
    
    // Timeline badges
    > .timeline-badge {
      position: absolute;
      top: 0;
      left: @timeline-separator-width / 2;
      width: @timeline-badge-size;
      height: @timeline-badge-size;
      border-radius: 500px;
      margin-left: -(@timeline-badge-size/2);
      line-height: @timeline-badge-size;
      font-size: @timeline-badge-size * .4;
      text-align: center;
      color: #fff;
      z-index: 100;

      &.timeline-badge-sm {
        width: @timeline-badge-size-sm;
        height: @timeline-badge-size-sm;
        margin-left: -(@timeline-badge-size-sm/2);
        line-height: @timeline-badge-size-sm;
        font-size: @timeline-badge-size-sm * .4;
      }
      
      
    }
    
  }
}






// Timeline title
// --------------------------------
.timeline-title {
    margin-top: 0;
    color: inherit;
}

// Timeline Body
// --------------------------------
.timeline-body > p,
.timeline-body > ul {
    margin-bottom: 0;
}
.timeline-body > p + p {
    margin-top: 5px;
}

// Timeline for Desktops
// --------------------------------
@media @desktop {
    
    .timeline {
      &:before {
        left: 50%;
        margin-left: (@timeline-badge-size/2);
      }
      > li {

        &.timeline-separator {
          margin-left: (@timeline-badge-size/2);
          text-align: center;
          &:before {
            margin-left: (@timeline-badge-size/2);
          }
          &:after {
            display: none;
          }
        }

        > .timeline-badge {
          left: 50%;
          margin-left: 0;
        }

        > .timeline-date {
          float: none;
          position: absolute;
          width: 40%;
          left: 50%;
          top: @timeline-badge-size / 2;
          margin-left: @timeline-badge-size * 1.5;
          margin-top: -(@timeline-datetime-height/2);
        }

        > .timeline-panel {
          float: left;
          margin: 0;
          width: 48%;

          &:before {
            border-left-width: 15px;
            border-right-width: 0;
            top: 26px;
            right: -15px;
            left: auto;
          }

          &:after {
            border-left-width: 14px;
            border-right-width: 0;
            top: 27px;
            right: -14px;
            left: auto;
          }
          
          .popover .arrow {
            top: @timeline-badge-size/2;
            display: block;
          }
        }

        // Timeline inverted
        // --------------------------------
        &.timeline-inverted {

            > .timeline-panel {
              float: right;
              padding-left: @timeline-badge-size;
            }

            .timeline-date  {
              left: auto;
              right: 50%;
              width: auto;
              margin-left: 0;
              margin-right: @timeline-badge-size;
            }
        }

      }
    }
    
}
