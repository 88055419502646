/* Roboto @font-face kit */
.height1{
  height: 52px;
}
.padding0{
  padding-left: 0px;
  padding-right: 0px;
}
.margin0{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.red{
  color: red;
  font-weight: 100;
}
.headerstyle{
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}
.wrapper input[type="text"] {
  position: relative;
}

input { font-family: 'FontAwesome'; } /* This is for the placeholder */

.wrapper:before {
  font-family: 'FontAwesome';
  color:red;
  position: relative;
  left: -10px;
  content: "\f007";
}
.headbottom{
  margin-bottom: 10px !important;
}
.marbottom{
  margin-bottom: 0px;
}
.fontstyle{
  font-weight: 100  !important;
}
@import url(//fonts.googleapis.com/css?family=Roboto:500,400italic,100,700italic,300,700,400);
