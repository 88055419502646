/**
   Component: uiselect.less
*/


.ui-select-bootstrap>.ui-select-match {
  text-align: left;
}

.ui-select-toggle {
  font-size: 14px;
}

