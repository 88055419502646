/**
   Component: progress-extra.less
*/
 
// Different size of Progress bars

@progress-height-sm:  14px;
@progress-height-xs:  8px;
@progress-height-xxs: 2px;

.progress-sm {
  height: @progress-height-sm;
}

.progress-xs {
  height: @progress-height-xs;
}

.progress-xxs {
  height: @progress-height-xxs;
}

// Progress color variants

.progress-bar-purple {
  .progress-bar-variant(@brand-purple);
}
.progress-bar-inverse {
  .progress-bar-variant(@brand-inverse);
}
.progress-bar-amber {
  .progress-bar-variant(@brand-amber);
}
.progress-bar-pink {
  .progress-bar-variant(@brand-pink);
}
